import type { FC, ReactNode } from 'preact/compat'
import { MultiSelectGroupedListContainer } from './MultiSelectGroupedListContainer'
import { MultiSelectListContainer } from './MultiSelectListContainer'

export type ComboboxProps = {
  optional?: boolean
  placeholder?: string
  isGroupedSpecialties?: boolean
  label?: string
  isMobile?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  buttonWrapperClass?: string
  variant?: 'contained' | 'outlined'
  selectLater?: {
    name: string
    label: string
    selected?: boolean
  }
  groupedSpecialties?: {
    name: string
    label: string
    searched?: boolean
    selected?: boolean
    filtered?: boolean
    specialties: {
      name: string
      label: string
      groups?: Array<{
        value: string
        label: string
      }>
    }[]
  }[]
  selectedItems?:
    | {
        label: string
        name: string
      }[]
    | null
  specialties?: {
    name: string
    label: string
    searched?: boolean
    selected?: boolean
    filtered?: boolean
  }[]

  open?: boolean
  handleOpenClick?: (open: boolean) => void
  handleSelectLater?: (selected: boolean) => void
  handleGetSelectedItems?: (
    items: {
      label: string
      name: string
      selected: boolean
    }[]
  ) => void
  handleGetSelectedItem?: (item: {
    label: string
    name: string
    selected: boolean
  }) => void
}
export const ComboboxButtonMultiSelect: FC<ComboboxProps> = ({
  specialties,
  groupedSpecialties,
  handleGetSelectedItems,
  optional,
  handleOpenClick,
  open,
  placeholder,
  label,
  selectedItems,
  isMobile,
  selectLater,
  handleSelectLater,
  endIcon,
  isGroupedSpecialties
}) => {

  return (
    <>
      {isGroupedSpecialties ? (
        <>
          {groupedSpecialties && (
            <MultiSelectGroupedListContainer
              isMobile={isMobile}
              isGroupedSpecialties={true}
              handleOpenClick={handleOpenClick}
              buttonWrapperClass='border-gt-black w-11/12 md:border-t border-b md:border-r my-2 md:my-0'
              open={open}
              data={groupedSpecialties}
              endIcon={endIcon}
              placeholder={placeholder}
              label={label}
              optional={optional}
              handleGetSelectedItems={handleGetSelectedItems}
            />
          )}
        </>
      ) : (
        <>
          {specialties && (
            <MultiSelectListContainer
              isMobile={isMobile}
              handleOpenClick={handleOpenClick}
              buttonWrapperClass='border-gt-black w-11/12 md:border-t border-b md:border-r my-2 md:my-0'
              open={open}
              data={specialties}
              endIcon={endIcon}
              placeholder={placeholder}
              label={label}
              optional={optional}
              handleGetSelectedItems={handleGetSelectedItems}
              selectedItems={selectedItems}
              selectLater={selectLater}
              handleSelectLater={handleSelectLater}
            />
          )}
        </>
      )}
    </>
  )
}
